<template>
  <section id="section-benefit" class="pt-0 pb-50px">
    <div class="nstra-container">
      <div style="z-index: 2" class="position-relative">
        <b-row>
          <b-col cols="12" md="6">
            <h2 v-scrollanimation="'left'" class="section-title">
              Perks of working
              <span class="text-primary">with us.</span>
            </h2>
            <div class="d-flex flex-column">
              <div v-scrollanimation="'left'" class="perks-item mb-30px">
                <img width="80" height="80" class="perks-icon" src="@/assets/icons/ico-offer-teams.svg" alt="team centric image" />
                <h4 class="perks-title">Team Centric</h4>
                <p class="perks-text">
                  With clear and common goals of success, each employee will set their own goals to achieve success of the project. Each member will receive guidance towards success, encouraged to
                  grow to be an independent, responsible and cooperative professional.
                </p>
              </div>
              <div v-scrollanimation="'left'" class="perks-item mb-30px">
                <img width="80" height="80" class="perks-icon" src="@/assets/icons/ico-offer-agile.svg" alt="knowledge sharing image" />
                <h4 class="perks-title">Knowledge Sharing</h4>
                <p class="perks-text">
                  With the team centric system, you are surrounded by a team of highly motivated individuals who will inspire you to level up your technical and interpersonal skills.
                </p>
              </div>
              <div v-scrollanimation="'left'" class="perks-item mb-30px">
                <img width="80" height="80" class="perks-icon" src="@/assets/icons/ico-offer-partnership.svg" alt="clear career path image" />
                <h4 class="perks-title">Clear Career Path</h4>
                <p class="perks-text">
                  Gain career opportunities and support structures to facilitate career moves within the company. You will have the opportunity to uncover interests, passions and hidden skills to find
                  fit in roles.
                </p>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div v-scrollanimation="'right'" class="perks-img-wrap position-relative">
              <img v-scrollanimation="'right'" class="perks-img perks-img-one reguler-shadow reguler-border-radius" src="@/assets/images/career/img-career-1.jpeg" alt="career image" />
              <img v-scrollanimation="'right'" class="float-dots d-none d-md-block" src="@/assets/images/dots-right-home.png" alt="hero-image" />
              <img
                class="perks-img perks-img-two float-img reguler-shadow reguler-border-radius d-none d-md-block"
                src="@/assets/images/career/img-career-2.jpeg"
                v-scrollanimation="'right'"
                alt="career image"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol
  },
};
</script>